import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/Layout';
import '../../assets/css/image-grid.css';

const pages = ['m~squared', 'm~house', 'm~pineapple'];

const Arty = ({ location }) => (
  <Layout location={location}>
    <div>
      <ul className="image-grid">
        {
          pages.map((page, i) => (
            <li key={i}>
              <h5 className="title"><Link to={`/arty/${page}`}>{page}</Link></h5>
            </li>    
          ))
        }
      </ul>
    </div>
  </Layout>
);

export default Arty;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;